<template>
  <project-page>
    <div class="relative">
      <icon-button
        :icon="arrowBackOutline"
        primary
        size="medium"
        @click="back"
        class="absolute top-3 left-3"
      />
      <project-details />
    </div>
  </project-page>
</template>

<script>
import { defineComponent } from 'vue'
import { arrowBackOutline } from 'ionicons/icons'

import ProjectPage from '@/components/templates/ProjectPage'
import IconButton from '@/components/atoms/IconButton'
import pageTitle from '@/utils/pageTitle'
import ProjectDetails from '@/components/organisms/ProjectDetails'

import { back } from '@/plugins/router'

export default defineComponent({
  components: {
    ProjectPage,
    IconButton,
    ProjectDetails,
  },
  setup() {
    return {
      arrowBackOutline,
    }
  },
  methods: {
    back() {
      back('project', this.$route.params)
    },
  },
  mounted() {
    // this might cause issues due to vue-router optimization (reusable components)
    // but right now title is only used for desktop environment which doesn't change the page
    document.title = pageTitle(this.project?.name)
  },
})
</script>
